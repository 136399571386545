const MEGA_NAV_SELECTOR = '.js-menu-item.is-mega'
const MEGA_SUB_NAV_SELECTOR = '.js-sub-mega-nav'
const MEGA_NAV_OPEN_CLASS = 'is-mega-nav-open'
const ACTIVE_CLASS = 'is-active'
class MegaNav extends HTMLElement {
  mainNav = null
  constructor () {
    super()
    this.megaNavItems = Array.prototype.slice.call(this.querySelectorAll(MEGA_NAV_SELECTOR))
    if (this.megaNavItems && this.megaNavItems.length > 0) {
      this.init()
    }
  }

  init () {
    this.megaNavItems.forEach(megaNavItem => {
      megaNavItem.addEventListener('mouseenter', (e) => {
        this.initMegaMenu()
        this.handleMouseEnter()
        const navWrapperEl = e.target
        const megaSubNavEl = navWrapperEl.querySelector(MEGA_SUB_NAV_SELECTOR)
        megaSubNavEl.style.maxHeight = `${megaSubNavEl.scrollHeight}px` 

      })

      megaNavItem.addEventListener('mouseleave', () => {
        this.initMegaMenu()
        this.handleMouseLeave()
      })
    })
  }

  initMegaMenu () {
    this.megaNavItems.forEach(megaNavItem => {
      const megaSubNavEl = megaNavItem.querySelector(MEGA_SUB_NAV_SELECTOR)
      megaSubNavEl.style.maxHeight = 0

      if (megaNavItem.classList.contains(ACTIVE_CLASS)) {
        megaNavItem.classList.remove(ACTIVE_CLASS)
      }
    })
  }

  handleMouseEnter () {
    document.body.classList.add(MEGA_NAV_OPEN_CLASS)
  }

  handleMouseLeave () {
    document.body.classList.remove(MEGA_NAV_OPEN_CLASS)
  }

  openMegaMenu (parent) {
    this.initMegaMenu()
    document.body.classList.add(MEGA_NAV_OPEN_CLASS)
    const megaSubNavEl = parent.querySelector(MEGA_SUB_NAV_SELECTOR)
    megaSubNavEl.style.maxHeight = `${megaSubNavEl.scrollHeight}px`
    parent.classList.toggle(ACTIVE_CLASS)
  }
}

customElements.define('mega-nav', MegaNav)
